import axios from 'axios'
import React from 'react'

/**
 * URL de l'api
 */
const SERVER_URL = process.env.REACT_APP_URLSERVER
/**
 * URL du CDN
 */
const CDN_URL = process.env.REACT_APP_CDN_URL

/**
 * Configuration basique de axios
 * @type {{withCredentials: boolean}}
 */
const AXIOS_CONFIG = {
	withCredentials: true,
}

/**
 * Configuration de axios pour fetch les PDF.
 * @type {{headers: {Accept: string, "Content-Type": string}, cache: string, withCredentials: boolean}}
 */
const AXIOS_PDF_CONFIG = {
	withCredentials: true,
	cache: 'reload',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/x-www-form-urlencoded',
	},
}

/**
 * Liste des requêtes disponibles.
 */
export default class Query extends React.Component {
	/**
	 * Gère les erreurs basiques des requetes.
	 *
	 * Si 401 : l'utilisateur sera déconnecté.
	 *
	 * Retourne le code d'erreur.
	 *
	 * @param error Erreur axios
	 * @param context Contexte global App.js
	 * @returns {((code: number) => any)|number}
	 */
	static handleError = (error, context) => {
		const status = error.response ? error.response.status : 500

		if (status === 401) {
			context.logout()
		}

		return status
	}

	/**
	 * Gère les erreurs lors de l'import en masse.
	 *
	 * Renvoie l'erreur plutôt que le code.
	 *
	 * @param error
	 * @param context
	 * @returns {T}
	 */
	static handleImportError = (error, context) => {
		const status = error.response ? error.response.status : 500

		if (status === 401) {
			return context.logout()
		}

		return error.response.data
	}

	/*** *** *** ***/
	/*** SCHOOL  ***/
	/*** *** *** ***/

	/**
	 * Ajoute un établissement à la liste de ceux éxistant.
	 * @param context
	 * @param uai UAI du nouvel établissement
	 * @param isEtabExist
	 * @returns {Promise<unknown>}
	 */
	static create = (context, uai, isEtabExist, state) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + '/schools/', { uai, isEtabExist, state }, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Supprime un établissement.
	 * @param context
	 * @param uai UAI de l'établissement à supprimer
	 * @returns {Promise<unknown>}
	 */
	static delete = (context, uai) =>
		new Promise((resolve, reject) => {
			return axios
				.delete(SERVER_URL + '/schools/' + uai, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Récupère l'ensemble des établissements.
	 * @param context
	 * @returns {Promise<unknown>}
	 */
	static getAll = context =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + '/schools/', AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Récupère l'ensemble le type et le secteur de l'établissements.
	 * @param context
	 * @returns {Promise<unknown>}
	 */
	static getDataSchools = context =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + '/schools/data/', AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Retourne les statistique globale du manager
	 * @param context
	 * @return {Promise<unknown>}
	 */
	static getGlobalStats = context =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + '/schools/stats', AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Récupère les informations d'un établissement.
	 * @param context
	 * @param uai UAI de l'établissement
	 * @returns {Promise<unknown>}
	 */
	static getSchool = (context, uai) =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + `/schools/${uai}`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Récupère les applications d'un établissement.
	 * @param context
	 * @param uai
	 * @returns {Promise<unknown>}
	 */
	static getApps = (context, uai) =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + `/schools/${uai}/applis`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Récupère les utilisateurs d'un établissement
	 * @param context
	 * @param uai
	 * @returns {Promise<unknown>}
	 */
	static getUsers = (context, uai) =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + `/schools/${uai}/users`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Met à jour les paramètres d'un établissement.
	 * @param context
	 * @param uai
	 * @param newParams
	 * @returns {Promise<unknown>}
	 */
	static updateParams = (context, uai, newParams) =>
		new Promise((resolve, reject) => {
			return axios
				.patch(SERVER_URL + `/schools/${uai}/params`, newParams, AXIOS_CONFIG)
				.then(res => resolve(res.data[0]))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Renvoie les informations de contact de l'établissement qui sont renseigné sur Dolibarr.
	 * @param context
	 * @param uai
	 * @return {Promise<unknown>}
	 */
	static getContact = (context, uai) =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + `/schools/${uai}/contact`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Synchronise les tokes LeLivreScolaire pour un établissement.
	 * @param context
	 * @param uai
	 * @returns {Promise<unknown>}
	 */
	static synchronizeEdulibTokens = (context, uai) =>
		new Promise((resolve, reject) => {
			return axios
				.post(`${SERVER_URL}/apps/edulib/update/${uai}`, {}, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Synchronise LibManuel pour un établissement.
	 * @param context
	 * @param uai
	 * @returns {Promise<unknown>}
	 */
	static synchronizeLeLivreScolaireTokens = (context, uai) =>
		new Promise((resolve, reject) => {
			return axios
				.post(`${SERVER_URL}/apps/le-livre-scolaire/update/${uai}`, {}, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Moissonne Ecole Directe pour récupérer tous les utilisateurs d'un établissements, et les importer dans le Manager.
	 * @param context
	 * @param {string} uai UAI de l'établissement du manager, dans lequel seront importés les utilisateurs
	 * @param {string} token Token d'accès lié à l'établissement dans Ecole Directe.
	 * @param {string} adjacentUai UAI d'un sous établissement lié à l'établissement principal
	 * @param {boolean} nextYear Currente année scolaire si date < 14/07, sinon année n+1
	 * @return {Promise<unknown>}
	 */
	static syncingEcoleDirecte = (context, uai, token, adjacentUai, nextYear, profil) =>
		new Promise((resolve, reject) => {
			return axios
				.post(
					SERVER_URL + `/users/batch/ecole-directe/${uai}`,
					{
						token,
						adjacentUai,
						nextYear,
						profil,
					},
					AXIOS_CONFIG
				)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleImportError(err, context)))
		})

	/**
	 * Moissonne Ecole Directe pour récupérer tous les utilisateurs d'un établissements et retourne un csv
	 * @param context
	 * @param token
	 * @param adjacentUai
	 * @param nextYear
	 * @returns {Promise<unknown>}
	 */
	static fetchEcoleDirecteDataToCsv = (context, uai, adjacentUai, nextYear, profil) => {
		let url = SERVER_URL + `/schools/${uai}/exports/ecole-directe?profil=${profil}&adjacentUai=${adjacentUai}`

		if (nextYear) url += '&nextYear=true'

		return new Promise((resolve, reject) => {
			return axios
				.get(url, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleImportError(err, context)))
		})
	}

	static fetchEcoleDirecteEnseignementsToCsv = (context, uai, adjacentUai, nextYear) => {
		let url = SERVER_URL + `/schools/${uai}/exports/ecole-directe/enseignements?adjacentUai=${adjacentUai}`

		if (nextYear) url += '&nextYear=true'

		return new Promise((resolve, reject) => {
			return axios
				.get(url, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleImportError(err, context)))
		})
	}

	static getTokenReport = context =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + '/apps/token-report', AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Récupère la liste des Mdms et du matériel pouvant exister.
	 * @param context
	 * @return {Promise<unknown>}
	 */
	static getMdmsAndMaterials = context =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + '/schools/deploy', AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Récupère le workflow (suivi) d'un établissement
	 * @param context
	 * @param uai
	 * @returns {Promise<unknown>}
	 */
	static getSchoolWorkflow = (context, uai) =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + `/workflows/${uai}`, AXIOS_CONFIG)
				.then(res => resolve({ steps: res.data.data, status: res.data.statusList }))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Met à jour le workflow d'un établissement
	 * @param context
	 * @param uai
	 * @param steps
	 * @return {Promise<unknown>}
	 */
	static updateSchoolWorkflow = (context, uai, steps) =>
		new Promise((resolve, reject) => {
			return axios
				.put(SERVER_URL + `/workflows/${uai}`, steps, AXIOS_CONFIG)
				.then(({ data, status }) => resolve({ data, status }))
				.catch(err => reject(this.handleImportError(err, context)))
		})

	/**
	 * Récupère le suivi de l'ensemble des établissements.
	 * @param context
	 * @return {Promise<unknown>}
	 */
	static getWorkflow = context =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + '/workflows', AXIOS_CONFIG)
				.then(res => resolve({ steps: res.data.data, status: res.data.statusList }))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Récupère le workflow (suivi) d'un établissement
	 * @param context
	 * @param uai
	 * @returns {Promise<unknown>}
	 */
	static getSchoolWorkflow = (context, uai) =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + `/workflows/${uai}`, AXIOS_CONFIG)
				.then(res => resolve({ steps: res.data.data, status: res.data.statusList }))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Met à jour le workflow d'un établissement
	 * @param context
	 * @param uai
	 * @param steps
	 * @return {Promise<unknown>}
	 */
	static updateSchoolWorkflow = (context, uai, steps) =>
		new Promise((resolve, reject) => {
			return axios
				.put(SERVER_URL + `/workflows/${uai}`, steps, AXIOS_CONFIG)
				.then(({ data, status }) => resolve({ data, status }))
				.catch(err => reject(this.handleImportError(err, context)))
		})

	/*** *** *** ***/
	/*** USER    ***/
	/*** *** *** ***/

	/**
	 * Ajout unitaire d'un utilisateur.
	 * @param context
	 * @param user
	 * @returns {Promise<unknown>}
	 */
	static addUser = (context, user) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + '/users', user, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Check si l'utilisateur est connecté.
	 * @param context
	 * @param userId
	 * @returns {Promise<unknown>}
	 */
	static isUserLogged = (context, userId, loginWithUrl = false) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + `/auth/login/token`, { id: userId, loginWithUrl }, AXIOS_CONFIG)
				.then(res => resolve({ user: res.data, apiVersion: res.headers['x-api-version'] }))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Récupère les informations d'un utilisateur.
	 * @param context
	 * @param userId
	 * @returns {Promise<unknown>}
	 */
	static getUser = (context, userId) =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + `/users/${userId}`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Recherche en base d'un utilisateur selon son nom/prénom.
	 * @param context
	 * @param username
	 * @returns {Promise<unknown>}
	 */
	static searchForOneUser = (context, username) =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + `/users?name=${username}`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Modifie le mot de passe d'un utilisateur.
	 * @param context
	 * @param actualPassword
	 * @param newPassword
	 * @returns {Promise<unknown>}
	 */
	static modifyPassword = (context, actualPassword, idUser, newPassword) =>
		new Promise((resolve, reject) => {
			return axios
				.patch(SERVER_URL + `/users/${idUser}/password`, { actualPassword, newPassword }, AXIOS_CONFIG)
				.then(() => resolve())
				.catch(err => reject(this.handleImportError(err, context)))
		})
	/**
	 * Authentifie un utilisateur.
	 * @param context
	 * @param login
	 * @param password
	 * @returns {Promise<unknown>}
	 */
	static logUser = (context, login, password) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + '/auth/login', { login: login, password: password }, AXIOS_CONFIG)
				.then(res => resolve({ user: res.data, apiVersion: res.headers['x-api-version'] }))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Déconnecte un utilisateur
	 * @param context
	 * @returns {Promise<unknown>}
	 */
	static logoutUser = context =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + '/auth/logout', AXIOS_CONFIG)
				.then(res => resolve())
				.catch(err => reject())
		})

	/**
	 * Récupère les entêtes pour la grille.
	 * @param context
	 * @returns {Promise<unknown>}
	 */
	static getUsersHeader = context =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + '/users/headers', AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Récupère les différents profils : Elève, Enseignant, Gestionnaire ...
	 * @param context
	 * @returns {Promise<unknown>}
	 */
	static getUsersProfile = context =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + '/users/profils', AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Met à jour les informations d'un utilisateur depuis sa fiche utilisateur.
	 * @param context
	 * @param idUser
	 * @param newUser
	 * @returns {Promise<unknown>}
	 */
	static updateUserPage = (context, idUser, newUser) =>
		new Promise((resolve, reject) => {
			return axios
				.put(SERVER_URL + `/users/${idUser}`, newUser, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Récupère les mots de passe des applications.
	 * @param context
	 * @param ids
	 * @param application
	 * @returns {Promise<unknown>}
	 */
	static getAppsPassword = (context, ids, application) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + '/apps/passwords', { ids, application }, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Supprime des utilisateurs. Ils doivent être préalablement désactivés.
	 * @param context
	 * @param ids
	 * @returns {Promise<unknown>}
	 */
	static purgeUsers = (context, ids) =>
		new Promise((resolve, reject) => {
			return axios
				.delete(SERVER_URL + '/users', { data: ids, ...AXIOS_CONFIG })
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Active ou désactiver des utilisateurs.
	 * @param context
	 * @param idsAndValue
	 * @returns {Promise<unknown>}
	 */
	static enableOrDisableUsers = (context, idsAndValue) =>
		new Promise((resolve, reject) => {
			return axios
				.patch(SERVER_URL + '/users/deactivate', idsAndValue, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Mise à jour en masse d'utilisateur
	 * @param context
	 * @param ids Liste des utilisateurs mappé selon leur id
	 * @param column Champ à modifier
	 * @param value Nouvelle valeur
	 * @param type Std/Opt/App POUR Standard/Options/Applications
	 * @returns {Promise<unknown>}
	 */
	static updateBulk = (context, ids, column, value, type, idApp) =>
		new Promise((resolve, reject) => {
			return axios
				.patch(SERVER_URL + '/users/', { ids, column, value, type, idApp }, AXIOS_CONFIG)
				.then(res => resolve(res.data[0]))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Génère des mots de passe aléatoire.
	 * @param context
	 * @param nb Qté de mot de passe à générer.
	 * @returns {Promise<unknown>}
	 */
	static getRandomPasswords = (context, nb) =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + `/users/passwords/${nb}`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Envoie un mot de passe pour que l'API le décrypte et nous le renvoi en clair.
	 * @returns {Promise<unknown>}
	 * @param context
	 * @param passwords
	 */
	static decryptPassword = (context, passwords = []) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + '/users/passwords/decrypt', { passwords }, AXIOS_CONFIG)
				.then(res => resolve(res.data.decrypted))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Activation des clés PNE.
	 * @param context
	 * @param params
	 * @returns {Promise<unknown>}
	 */
	static activatePNE = (context, params) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + '/users/pne', params, AXIOS_CONFIG)
				.then(res => resolve(res))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Mise à jour d'un utilisateur depuis la grille.
	 * @param context
	 * @param uid ID de l'utilisateur
	 * @param key Champ
	 * @param value Nouvelle valeur
	 * @returns {Promise<unknown>}
	 */
	static updateUserInGrid = (context, uid, key, value) =>
		new Promise((resolve, reject) => {
			return axios
				.patch(SERVER_URL + `/users/${uid}`, { key, value }, AXIOS_CONFIG)
				.then(res => resolve(res.data[0]))
				.catch(err => reject(this.handleError(err, context)))
		})

	static loginWithCasIntoSupport = (context) =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + '/apps/support', AXIOS_CONFIG)
				.then(res => resolve(res.data.url))
				.catch(err => reject(this.handleError(err, context)))
		})

	/*** *** *** ***/
	/***   CSV   ***/
	/*** *** *** ***/

	/**
	 * Vérifie la validation d'un fichier CSV pour l'import en masse.
	 * @param context
	 * @param formdata
	 * @returns {Promise<unknown>}
	 */
	//
	static checkImportFile = (context, formdata, maj, importTeaching) =>
		new Promise((resolve, reject) => {
			if (importTeaching) {
				console.log({ importTeaching })
				return axios
					.put(SERVER_URL + '/users/teaching', formdata, {
						...AXIOS_CONFIG,
						headers: { 'Content-Type': 'multipart/form-data' },
					})
					.then(res => resolve(res.data))
					.catch(err => reject(this.handleImportError(err, context)))
			} else if (maj)
				return axios
					.patch(SERVER_URL + '/users/batch', formdata, {
						...AXIOS_CONFIG,
						headers: { 'Content-Type': 'multipart/form-data' },
					})
					.then(res => resolve(res.data))
					.catch(err => reject(this.handleImportError(err, context)))
			else
				return axios
					.post(SERVER_URL + '/users/batch', formdata, {
						...AXIOS_CONFIG,
						headers: { 'Content-Type': 'multipart/form-data' },
					})
					.then(res => resolve(res.data))
					.catch(err => reject(this.handleImportError(err, context)))
		})
	/**
	 * Confirme l'import d'un fichier précédemment validé.
	 * @param context
	 * @param importNumber
	 * @param confirm
	 * @returns {Promise<unknown>}
	 */
	static confirmImportFile = (context, importNumber, confirm, excludeUsers) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + `/users/batch/${importNumber}`, { confirmation: confirm, excludeUsers }, AXIOS_CONFIG)
				.then(res => (res.status === 201 ? resolve(res.data) : resolve(res.status)))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Récupère les informations pour effectuer un export CSV complet.
	 * @param context
	 * @param ids
	 * @returns {Promise<unknown>}
	 */
	static getFullExport = (context, ids) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + '/users/export', ids, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	static getTeachingExport = (context, ids) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + '/users/export/teaching', ids, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	static getPlatformsUsers = (context, userIds) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + '/users/platforms', { userIds }, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Récupère les informations pour effecuter un export CSV pour mise à jour.
	 * @param context
	 * @returns {Promise<unknown>}
	 */
	static getMajExport = context =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + '/getMajExport.php', AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/*** *** *** ***/
	/***   PDF   ***/
	/*** *** *** ***/

	/**
	 * Export PDF
	 * @param context
	 * @param type Type du PDF
	 * @param language Langue de l'export
	 * @param option Option de l'export
	 * @param data Liste des utilisateurs.
	 * @returns {Promise<unknown>}
	 */
	static generatePDF = (context, type, language, option, data) =>
		new Promise((resolve, reject) => {
			return axios
				.post(
					SERVER_URL + '/pdf/' + type,
					{
						list: data,
						lang: language,
						option,
					},
					{ ...AXIOS_CONFIG, responseType: 'blob' }
				)
				.then(res => resolve(new Blob([res.data], { type: option === 'default' ? 'application/pdf' : 'application/zip' })))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Ping l'api pour savoir ou en est la génération.
	 * @param context
	 * @param uid
	 * @returns {Promise<unknown>}
	 */
	static ping = (context, uid) =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + `/pdf/${uid}`, AXIOS_PDF_CONFIG)
				.then(res => resolve(res))
				.catch(err => reject(this.handleError(err, context)))
		})

	/*** *** *** ***/
	/*** APPS    ***/
	/*** *** *** ***/

	/**
	 * Récupère les URL web des applications pour authentifier les utilisateurs en ligne.
	 * @param context
	 * @param params
	 * @returns {Promise<unknown>}
	 */
	static getUrls = (context, params) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + '/getAppliURLWeb.php', { json: params }, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/*** *** *** *** *** ***/
	/***  FILES MANAGER  ***/
	/*** *** *** *** *** ***/

	/**
	 * Permet de generer l'URL de téléchargement d'un fichier spécifique.
	 * @param context
	 * @param file
	 * @returns Promise{unknown}
	 */
	static getDownloadURL = (context, file) =>
		new Promise((resolve, reject) => {
			return axios
				.get(`${SERVER_URL}/files/download/url/${file}`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Récupère la liste des containers disponibles.
	 * @param {context} context
	 * @returns {Promise<unknown>}
	 */
	static fetchContainers = context =>
		new Promise((resolve, reject) => {
			return axios
				.get(CDN_URL + '/containers', AXIOS_CONFIG)
				.then(res => resolve(res.data.url))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Récupère les fichiers de l'UAI de l'utilisateur courant
	 * @param {context} context
	 * @param {string} uai
	 * @returns {Promise<unknown>}
	 */
	static getFiles = (context, uai = '*') =>
		new Promise((resolve, reject) => {
			return axios
				.get(`${SERVER_URL}/files/${uai}`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Permet de créer un dossier.
	 * @param {context} context
	 * @param {string} container
	 * @param {string} folderName
	 * @param {string} parentFolderName
	 * @returns {Promise<unknown>}
	 */
	static createDir = (context, container, folderName, parentFolderName = '') =>
		new Promise((resolve, reject) => {
			return axios
				.post(`${CDN_URL}/${container}/${parentFolderName}`, { folderName }, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Récupère les données d'un fichier : nom, poids, date de création, droits, établissements affectés...
	 * @param {context} context
	 * @param {string} container
	 * @param {string} object
	 * @return {Promise<unknown>}
	 */
	static getFileData = (context, container, object) =>
		new Promise((resolve, reject) => {
			return axios
				.head(`${CDN_URL}/${container}/${object}`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Permet d'uploader un fichier dans un container S3 depuis une url d'upload.
	 * Il est possible de spécifier.
	 * @param context
	 * @param uploadURL
	 * @param fields
	 * @param file
	 * @param onUploadProgress
	 * @returns {Promise<unknown>}
	 */
	static uploadFile = (context, uploadURL, fields, file, onUploadProgress) => {
		const formdata = new FormData()
		for (const key in fields) formdata.append(key, fields[key])
		formdata.append('file', file)

		return new Promise((resolve, reject) => {
			return axios
				.post(uploadURL, formdata, {
					...AXIOS_CONFIG,
					headers: { 'Content-Type': 'multipart/form-data' },
					onUploadProgress,
				})
				.then(res => resolve(res.data))
				.catch(err => reject(err))
		})
	}

	/**
	 * Permet de recuperer l'URL pour upload dans le bucket du manager.
	 * @param context
	 * @param file
	 * @param {boolean} isApp
	 * @returns {Promise<unknown>}
	 */
	static getUploadFileURL = (context, file, isApp = false) =>
		new Promise((resolve, reject) => {
			return axios
				.get(`${SERVER_URL}/files/upload${isApp ? '/app' : ''}/${file}`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Permet d'ajouter un fichier en bdd.
	 * @param context
	 * @param arrayDocs
	 * @returns {Promise<unknown>}
	 */
	static addFilesinDB = (context, arrayDocs) =>
		new Promise((resolve, reject) => {
			return axios
				.put(`${SERVER_URL}/files/`, { arrayDocs }, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Permet de supprimer un fichier en BDD et sur S3.
	 * @param context
	 * @param fileHash
	 * @returns {Promise<unknown>}
	 */
	static deleteFileInDB = (context, fileHash) =>
		new Promise((resolve, reject) => {
			return axios
				.delete(`${SERVER_URL}/files/${fileHash}`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Permet de supprimer différents fichiers en BDD et sur S3.
	 * @param context
	 * @param filesHash
	 * @return {Promise<unknown>}
	 */
	static deleteFilesInDB = (context, filesHash) =>
		new Promise((resolve, reject) => {
			return axios
				.post(
					`${SERVER_URL}/files`,
					{
						action: 'delete',
						files: filesHash,
					},
					AXIOS_CONFIG
				)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Rename a folder or a file.
	 * @param context
	 * @param container
	 * @param object
	 * @param newName
	 * @returns {Promise<unknown>}
	 */
	static renameFile = (context, container, object, newName) =>
		new Promise((resolve, reject) => {
			return axios
				.patch(`${CDN_URL}/${container}/${object}`, { newName }, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})
	/**
	 * Delete a file from the container.
	 * @param {context} context
	 * @param {string} container
	 * @param {string} object
	 * @return {Promise<unknown>}
	 */
	static removeFile = (context, container, object) =>
		new Promise((resolve, reject) => {
			return axios
				.delete(`${CDN_URL}/${container}/${object}`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Affecte une liste de documents à une liste d'établissements.
	 * @param {context} context
	 * @param {[string]} arrayDocs
	 * @param {boolean|[string]} arrayUai
	 * @return {Promise<unknown>}
	 */
	static affectSchools = (context, arrayDocs, arrayUai) =>
		new Promise((resolve, reject) => {
			const schools = typeof arrayUai === 'boolean' ? ['all'] : arrayUai

			return axios
				.patch(`${SERVER_URL}/files/schools`, { arrayDocs, arrayUai: schools }, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Affecte ou désaffecte des documents à des utilisateurs.
	 * @param {context} context
	 * @param {"insert"|"delete"}action
	 * @param {string[]} arrayDocs
	 * @param {string[]} arrayUsers
	 * @return {Promise<unknown>}
	 */
	static affectUsers = (context, action, arrayDocs, arrayUsers) =>
		new Promise((resolve, reject) => {
			return axios
				.patch(`${SERVER_URL}/users/files`, { action, arrayDocs, arrayUsers }, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/*** *** *** *** ***
	 *** SETTINGS    ***
	 *** *** *** *** ***/

	/**
	 * Récupère les paramètres globaux
	 * @param context
	 * @param {'apps'|'regex'|'tabuleo'|'platforms'} param
	 * @returns {Promise<unknown>}
	 */
	static getGlobalSettings = (context, param) =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + '/settings/' + param, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/**
	 * Modifie les paramètres globaux
	 * @param context
	 * @param {'apps'|'regex'|'tabuleo'|'platforms'} param
	 * @param {object} values
	 * @returns {Promise<unknown>}
	 */
	static updateGlobalSettings = (context, param, values) =>
		new Promise((resolve, reject) => {
			return axios
				.put(SERVER_URL + '/settings/' + param, values, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/*** *** *** *** *** ***/
	/*** Notifications   ***/
	/*** *** *** *** *** ***/

	static sendNotification = (context, userIds, title, body, link, action, data) =>
		new Promise((resolve, reject) => {
			return axios
				.post(
					SERVER_URL + `/devices/notifications`,
					{
						userIds,
						notification: { title, body, custom: { action, data, link } },
					},
					AXIOS_CONFIG
				)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	static sendNotificationMassive = (context, userIds, title, body, link, arrayUai, arrayProfiles, arrayApplications, subject, logicalOperator, searchOperator, nbUser, nbToken, successCount, failureCount) =>
		new Promise((resolve, reject) => {
			return axios
				.post(
					SERVER_URL + `/devices/notifications/massive`,
					{
						userIds,
						notification: { title, body, custom: { link } },
						parametersNotification: {
							arrayUai,
							arrayProfiles,
							arrayApplications,
							subject: [
								{
									terms: subject,
									logicalOperator,
									searchOperator,
								},
							],
						},
						nbUser,
						nbToken,
						successCount,
						failureCount,
					},
					AXIOS_CONFIG
				)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	static saveNotification = (context, labelNotification, descriptionNotification, linkNotification, arrayUai, arrayProfiles, arrayApplications, terms, logicalOperator, searchOperator, nbUser, nbToken) =>
		new Promise((resolve, reject) => {
			return axios
				.post(
					SERVER_URL + `/devices/notifications/save`,
					{
						labelNotification,
						descriptionNotification,
						linkNotification,
						parametersNotification: {
							arrayUai,
							arrayProfiles,
							arrayApplications,
							subject: [
								{
									terms,
									searchOperator,
									logicalOperator,
								},
							],
						},
						reportNotification: {
							nbUser,
							nbToken,
						},
					},
					AXIOS_CONFIG
				)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	static getTokensUserNotification = (context, arrayUai, arrayApplications, subject, arrayProfiles, count) =>
		new Promise((resolve, reject) => {
			return axios
				.post(
					SERVER_URL + '/devices/notifications/token',
					{
						arrayUai,
						arrayApplications,
						subject,
						arrayProfiles,
						count,
					},
					AXIOS_CONFIG
				)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	static getHistoriqueNotification = context =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + '/devices/notifications/historique', AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	/*** *** *** *** *** ***/
	/*** 	Tokens   	 ***/
	/*** *** *** *** *** ***/

	static getTokensScheduledTask = context =>
		new Promise((resolve, reject) => {
			return axios
				.get(SERVER_URL + `/tokens`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	static deleteTokenScheduledTask = (context, id) =>
		new Promise((resolve, reject) => {
			return axios
				.delete(SERVER_URL + `/tokens/${id}`, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	static editTokenScheduledTask = (context, scheduledTask) =>
		new Promise((resolve, reject) => {
			return axios
				.put(SERVER_URL + `/tokens/${scheduledTask.eventId}`, scheduledTask, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	static createTokenScheduledTask = (context, scheduledTask) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + `/tokens`, scheduledTask, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	static sendMails = (context, users) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + '/users/mails', users, AXIOS_CONFIG)
				.then(res => resolve(res.data))
				.catch(err => reject(this.handleError(err, context)))
		})

	// SFTP

	static sftpPing = (sftpUrl, sftpUser, sftpPassword, sftpPort, isSftp) =>
		new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + `/sftp/ping`, { sftpUrl, sftpUser, sftpPassword, sftpPort, isSftp }, AXIOS_CONFIG)
				.then(() => resolve())
				.catch(err => reject(err.response.status))
		})

	static pushSftpFile = (file, uai, serverData) => {
		const formdata = new FormData()
		formdata.append('zip', file)
		formdata.append('uai', uai)
		formdata.append('serverData', serverData)

		return new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + `/sftp/push`, formdata, {
					...AXIOS_CONFIG,
					headers: { 'Content-Type': 'multipart/form-data' },
				})
				.then(res => resolve(res.data.filename))
				.catch(err => reject(err.response.data.status))
		})
	}
	static downloadSftpArchive = filename => {
		return new Promise((resolve, reject) => {
			return axios
				.post(SERVER_URL + `/sftp/download`, { filename }, AXIOS_CONFIG)
				.then(res => {
					const link = document.createElement('a')
					link.href = res.data.url
					link.setAttribute('download', filename)
					link.click()

					resolve()
				})
				.catch(err => reject(err.response.data.status))
		})
	}
}
